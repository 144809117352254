






import { Component, Prop, Mixins } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'

@Component({
  components: {
    ColoredBox,
  },
})
export default class SubjectTitledBox extends Mixins(StyleSubjectBackground) {
  @Prop()
  title!: string

  @Prop()
  subjectCode!: string

  get setStyle(): {} {
    return this.getSubjectStyle(this.subjectCode)
  }
}
